exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-centro-de-ayuda-js": () => import("./../../../src/pages/centro-de-ayuda.js" /* webpackChunkName: "component---src-pages-centro-de-ayuda-js" */),
  "component---src-pages-contactenos-js": () => import("./../../../src/pages/contactenos.js" /* webpackChunkName: "component---src-pages-contactenos-js" */),
  "component---src-pages-denuncias-actos-de-corrupcion-js": () => import("./../../../src/pages/denuncias-actos-de-corrupcion.js" /* webpackChunkName: "component---src-pages-denuncias-actos-de-corrupcion-js" */),
  "component---src-pages-diversey-js": () => import("./../../../src/pages/diversey.js" /* webpackChunkName: "component---src-pages-diversey-js" */),
  "component---src-pages-export-en-about-us-js": () => import("./../../../src/pages/export/en/about-us.js" /* webpackChunkName: "component---src-pages-export-en-about-us-js" */),
  "component---src-pages-export-en-contact-js": () => import("./../../../src/pages/export/en/contact.js" /* webpackChunkName: "component---src-pages-export-en-contact-js" */),
  "component---src-pages-export-en-dispensers-js": () => import("./../../../src/pages/export/en/dispensers.js" /* webpackChunkName: "component---src-pages-export-en-dispensers-js" */),
  "component---src-pages-export-en-food-industry-js": () => import("./../../../src/pages/export/en/food-industry.js" /* webpackChunkName: "component---src-pages-export-en-food-industry-js" */),
  "component---src-pages-export-en-hand-hygiene-js": () => import("./../../../src/pages/export/en/hand-hygiene.js" /* webpackChunkName: "component---src-pages-export-en-hand-hygiene-js" */),
  "component---src-pages-export-en-js": () => import("./../../../src/pages/export-en.js" /* webpackChunkName: "component---src-pages-export-en-js" */),
  "component---src-pages-export-en-products-js": () => import("./../../../src/pages/export/en/products.js" /* webpackChunkName: "component---src-pages-export-en-products-js" */),
  "component---src-pages-export-en-report-corruption-js": () => import("./../../../src/pages/export/en/report-corruption.js" /* webpackChunkName: "component---src-pages-export-en-report-corruption-js" */),
  "component---src-pages-export-en-social-responsability-js": () => import("./../../../src/pages/export/en/social-responsability.js" /* webpackChunkName: "component---src-pages-export-en-social-responsability-js" */),
  "component---src-pages-export-en-surface-disinfection-js": () => import("./../../../src/pages/export/en/surface-disinfection.js" /* webpackChunkName: "component---src-pages-export-en-surface-disinfection-js" */),
  "component---src-pages-export-en-thank-you-js": () => import("./../../../src/pages/export/en/thank-you.js" /* webpackChunkName: "component---src-pages-export-en-thank-you-js" */),
  "component---src-pages-export-en-tissues-js": () => import("./../../../src/pages/export/en/tissues.js" /* webpackChunkName: "component---src-pages-export-en-tissues-js" */),
  "component---src-pages-export-es-absorbentes-js": () => import("./../../../src/pages/export/es/absorbentes.js" /* webpackChunkName: "component---src-pages-export-es-absorbentes-js" */),
  "component---src-pages-export-es-acerca-de-js": () => import("./../../../src/pages/export/es/acerca-de.js" /* webpackChunkName: "component---src-pages-export-es-acerca-de-js" */),
  "component---src-pages-export-es-contacto-js": () => import("./../../../src/pages/export/es/contacto.js" /* webpackChunkName: "component---src-pages-export-es-contacto-js" */),
  "component---src-pages-export-es-denuncias-actos-de-corrupcion-js": () => import("./../../../src/pages/export/es/denuncias-actos-de-corrupcion.js" /* webpackChunkName: "component---src-pages-export-es-denuncias-actos-de-corrupcion-js" */),
  "component---src-pages-export-es-desinfeccion-de-superficies-js": () => import("./../../../src/pages/export/es/desinfeccion-de-superficies.js" /* webpackChunkName: "component---src-pages-export-es-desinfeccion-de-superficies-js" */),
  "component---src-pages-export-es-dispensadores-js": () => import("./../../../src/pages/export/es/dispensadores.js" /* webpackChunkName: "component---src-pages-export-es-dispensadores-js" */),
  "component---src-pages-export-es-gracias-js": () => import("./../../../src/pages/export/es/gracias.js" /* webpackChunkName: "component---src-pages-export-es-gracias-js" */),
  "component---src-pages-export-es-higiene-de-manos-js": () => import("./../../../src/pages/export/es/higiene-de-manos.js" /* webpackChunkName: "component---src-pages-export-es-higiene-de-manos-js" */),
  "component---src-pages-export-es-industria-alimenticia-js": () => import("./../../../src/pages/export/es/industria-alimenticia.js" /* webpackChunkName: "component---src-pages-export-es-industria-alimenticia-js" */),
  "component---src-pages-export-es-js": () => import("./../../../src/pages/export-es.js" /* webpackChunkName: "component---src-pages-export-es-js" */),
  "component---src-pages-export-es-productos-js": () => import("./../../../src/pages/export/es/productos.js" /* webpackChunkName: "component---src-pages-export-es-productos-js" */),
  "component---src-pages-export-es-responsabilidad-social-js": () => import("./../../../src/pages/export/es/responsabilidad-social.js" /* webpackChunkName: "component---src-pages-export-es-responsabilidad-social-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-politica-de-devolucion-js": () => import("./../../../src/pages/legal/politica-de-devolucion.js" /* webpackChunkName: "component---src-pages-legal-politica-de-devolucion-js" */),
  "component---src-pages-legal-politica-de-privacidad-js": () => import("./../../../src/pages/legal/politica-de-privacidad.js" /* webpackChunkName: "component---src-pages-legal-politica-de-privacidad-js" */),
  "component---src-pages-legal-terminos-y-condiciones-js": () => import("./../../../src/pages/legal/terminos-y-condiciones.js" /* webpackChunkName: "component---src-pages-legal-terminos-y-condiciones-js" */),
  "component---src-pages-lucha-contra-la-corrupcion-js": () => import("./../../../src/pages/lucha-contra-la-corrupcion.js" /* webpackChunkName: "component---src-pages-lucha-contra-la-corrupcion-js" */),
  "component---src-pages-politica-derechos-humanos-js": () => import("./../../../src/pages/politica-derechos-humanos.js" /* webpackChunkName: "component---src-pages-politica-derechos-humanos-js" */),
  "component---src-pages-politica-estandares-laborales-js": () => import("./../../../src/pages/politica-estandares-laborales.js" /* webpackChunkName: "component---src-pages-politica-estandares-laborales-js" */),
  "component---src-pages-politica-medio-ambiente-js": () => import("./../../../src/pages/politica-medio-ambiente.js" /* webpackChunkName: "component---src-pages-politica-medio-ambiente-js" */),
  "component---src-pages-productos-absorbentes-js": () => import("./../../../src/pages/productos/absorbentes.js" /* webpackChunkName: "component---src-pages-productos-absorbentes-js" */),
  "component---src-pages-productos-dispensadores-js": () => import("./../../../src/pages/productos/dispensadores.js" /* webpackChunkName: "component---src-pages-productos-dispensadores-js" */),
  "component---src-pages-productos-higiene-de-manos-js": () => import("./../../../src/pages/productos/higiene-de-manos.js" /* webpackChunkName: "component---src-pages-productos-higiene-de-manos-js" */),
  "component---src-pages-productos-js": () => import("./../../../src/pages/productos.js" /* webpackChunkName: "component---src-pages-productos-js" */),
  "component---src-pages-productos-lavanderia-js": () => import("./../../../src/pages/productos/lavanderia.js" /* webpackChunkName: "component---src-pages-productos-lavanderia-js" */),
  "component---src-pages-productos-linea-profesional-js": () => import("./../../../src/pages/productos/linea-profesional.js" /* webpackChunkName: "component---src-pages-productos-linea-profesional-js" */),
  "component---src-pages-productos-quimicos-js": () => import("./../../../src/pages/productos/quimicos.js" /* webpackChunkName: "component---src-pages-productos-quimicos-js" */),
  "component---src-pages-productos-secadores-de-manos-js": () => import("./../../../src/pages/productos/secadores-de-manos.js" /* webpackChunkName: "component---src-pages-productos-secadores-de-manos-js" */),
  "component---src-pages-productos-seguridad-ocupacional-js": () => import("./../../../src/pages/productos/seguridad-ocupacional.js" /* webpackChunkName: "component---src-pages-productos-seguridad-ocupacional-js" */),
  "component---src-pages-quienes-somos-js": () => import("./../../../src/pages/quienes-somos.js" /* webpackChunkName: "component---src-pages-quienes-somos-js" */),
  "component---src-pages-responsabilidad-social-js": () => import("./../../../src/pages/responsabilidad-social.js" /* webpackChunkName: "component---src-pages-responsabilidad-social-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-soluciones-por-sector-alto-trafico-js": () => import("./../../../src/pages/soluciones-por-sector/alto-trafico.js" /* webpackChunkName: "component---src-pages-soluciones-por-sector-alto-trafico-js" */),
  "component---src-pages-soluciones-por-sector-educacion-js": () => import("./../../../src/pages/soluciones-por-sector/educacion.js" /* webpackChunkName: "component---src-pages-soluciones-por-sector-educacion-js" */),
  "component---src-pages-soluciones-por-sector-industria-alimenticia-js": () => import("./../../../src/pages/soluciones-por-sector/industria-alimenticia.js" /* webpackChunkName: "component---src-pages-soluciones-por-sector-industria-alimenticia-js" */),
  "component---src-pages-soluciones-por-sector-js": () => import("./../../../src/pages/soluciones-por-sector.js" /* webpackChunkName: "component---src-pages-soluciones-por-sector-js" */),
  "component---src-pages-soluciones-por-sector-servicios-de-limpieza-js": () => import("./../../../src/pages/soluciones-por-sector/servicios-de-limpieza.js" /* webpackChunkName: "component---src-pages-soluciones-por-sector-servicios-de-limpieza-js" */),
  "component---src-pages-trabaja-con-nosotros-js": () => import("./../../../src/pages/trabaja-con-nosotros.js" /* webpackChunkName: "component---src-pages-trabaja-con-nosotros-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-product-template-js": () => import("./../../../src/templates/productTemplate.js" /* webpackChunkName: "component---src-templates-product-template-js" */),
  "component---src-templates-productexport-template-js": () => import("./../../../src/templates/productexportTemplate.js" /* webpackChunkName: "component---src-templates-productexport-template-js" */),
  "component---src-templates-productexporten-template-js": () => import("./../../../src/templates/productexportenTemplate.js" /* webpackChunkName: "component---src-templates-productexporten-template-js" */)
}

